<template>
	<div class="page-manage">
		<menu-list @click="addModule"></menu-list>

		<browse-box 
            ref="browseBox" 
            :list="list" 
            :isSetData="isAddData" 
            @submit="submit" 
            @moveUp="moveUp" 
            @moveDown="moveDown"
            @remove="remove">
		</browse-box>

		<div class="edit-box">
			<edit-box :list="list" @move="handleCanMove"></edit-box>
		</div>

		<div class="btn-box">
			<el-button plain size="mini" @click="cancel">取消</el-button>
			<!-- <el-button plain size="mini" @click="preview">预览</el-button> -->
			<el-button plain size="mini" @click="submit(0)">保存页面</el-button>
			<!-- <el-button type="primary" size="mini" @click="submit(1)">保存并发布</el-button> -->
		</div>

		<div class="fixed-menu" @click="showMenu=!showMenu">
			<!-- <Icon type="android-apps" :size="20" color="#1890ff"></Icon> -->
			<i class="el-icon-tickets" style="font-size: 20px;color:#1890ff;"></i>
		</div>

		<transition name="scaleRight">
			<div class="dragMenu" v-if="showMenu">
				<div class="box">
					<p class="title">模块管理</p>
					<draggable class="list" :list="list" :options="{animation: 100, handle:'.item'}">
						<div class="item" @click="scrollToElement(item)" v-for="(item, index) in list" :key="index" v-if="item.moduleType !== 1">
							{{item.tipName}}

							<span class="menu-delete" @click.stop="deleteMenu(index)" title="删除模块">
								<Icon type="trash-a" :size="20" color="#1890ff"></Icon>
							</span>
						</div>
					</draggable>
				</div>
			</div>
		</transition>


		<el-dialog title="提示" :visible.sync="dialogVisible" width="400px">
			<span style="line-height: 24px;">若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？</span>
			<span slot="footer" class="dialog-footer">
				<el-button size="mini" @click="dialogVisible = false">关闭</el-button>
				<el-button size="mini" type="primary" @click="sureCancel">确定离开</el-button>
			</span>
		</el-dialog>

		<el-dialog title="预览" :visible.sync="weiCodeShow" width="500px" @closed="weiCodeCancel">
			<div v-loading="codeding">
				<img :src="imgUrl+codePicture" alt="" v-if="codePicture" id="codeId" style="width:300px;display: block;margin: 30px auto">
			</div>
		</el-dialog>

	</div>
</template>

<script>
	import menuList from './components/menuList';
	import browseBox from './components/browseBox';
	import editBox from './components/editBox';
	import batchCods from "@/components/bathCodes"

	import draggable from 'vuedraggable';
	import config from '@/config/index'

	import {
        getPageAdd,
        getPageEdit,
		getPageDetail
	} from '@/api/cms';
	import apiList from '@/api/other';

	export default {
		name: 'page-manage',
		components: {
			menuList,
			browseBox,
			editBox,
			draggable,
			batchCods
		},
		data() {
			return {
				list: [],
				imgUrl: config.IMG_BASE,
				pageId: this.$route.query.id || 0,
				isAddData: false,
				showMenu: false,
				dialogVisible: false,
				indexList: [],
				weiCodeData: {},
				weiCodeShow: false,
				codeding: false,
				codePicture: '',
				isGroup: this.$route.query.isGroup === 'group'
			};
		},
		methods: {
			async initData() {

				if (!this.pageId || this.pageId == '0') {
					this.setNormal();
					return;
				}

				const params = {
					id: this.pageId
				}

				const res = await getPageDetail(params);

				try {
					var list = JSON.parse(res.data.decorateData);
                    console.log('list',list);
					list.map(item => {
						item.uid = item.uid || this.guid();

						if (!item.tipName && item.moduleType != 1) {
							item = this.filterRecord(item);
							if (this.indexList[item.moduleType]) {
								this.indexList[item.moduleType].push(item.index);
							} else {
								item.tipName = item.name + 1;
								item.index = item.index;
								this.indexList[item.moduleType] = [];
								this.indexList[item.moduleType].push(1);
							}
						} else {
							this.indexList[item.moduleType] = this.indexList[item.moduleType] || [];
							this.indexList[item.moduleType].push(item.index);
                            item.data.isShare = item.data.isShare == undefined ? true: item.data.isShare;
						}

						//list跟保存时候 返回的数据格式不一样， 把格式改成保存时一样的格式
						if (item.moduleType == 17) {
							item.data.list = item.data.list.Results||[];
						}
						
						if (item.moduleType == 24){
							item.data.list = item.data.list.Results.map(item=>{
								item.ImgUrlComplete = this.imgUrl + item.ImgUrl;
								item.ProductName = item.Name;
								item.ExclusivePrice = item.Price
								return item;
							});
						}
						
						//column是后加的字段， 需要再初始化的时候 把这个字段加上
						if (item.moduleType == 2) {
							if (!item.data.column) {
								item.data.column = '3';
							} else {
								item.data.column = item.data.column + '';
							}
						}
						
						if (item.moduleType == 16){
							if (typeof(item.data.showScan) == 'undefined'){
								item.data.showScan = false;
							}
						}
						return item;
					})

					this.list = list;

					console.log(this.list, 'this.list')
					if (!this.list.length) {
						this.setNormal();
					}
				} catch (error) {
					console.log(error)
					this.setNormal();
				}


			},
			setNormal() {
				this.list.push({
					moduleType: 1,
					name: '标题',
					tipName: '标题',
					uid: this.guid(),
					data: {
						visible: true,
						editVisible: true,
						title: '自定义页面',
						isShowShopName: true,
						isShowAttentionWechat: true,
						pageBg:'',
						titleBg:'',
						shareImage:'',//分享图片路径
						shareContent:'',
						showShare:false,//是否显示海报下载入口
						posterImage:'',//下载海报图片路径
                        isShare:true,   //分享开关
					}
				})
			},
			addModule(record) {
				var isKilled = false;
				this.list.map(item=>{
					if(item.moduleType==17){
						isKilled = true;
					}
				})
				if(isKilled && record.moduleType==17){
					return;
				}
				switch (record.moduleType) {
					case 2: //单排商品
						this.setProductData(record);
						break;
					case 3: //双排商品
						this.setProductData(record);
						break;
					case 4: //三排商品
						this.setProductData(record);
						break;
					case 5: //商品列表
						this.setProductData(record);
						break;
					case 6: //猜你喜欢
						this.setGuessData(record);
						break;
					case 7: //视频
						this.setVideoData(record);
						break;
					case 8: //轮播图
						this.setProductData(record);
						break;
					case 9: //单排图片
						this.setProductData(record);
						break;
					case 10: //双排图片
						this.setPictureData(record);
						break;
					case 11: //图文导航
						this.setPictureData(record);
						break;
					case 12: //热区
						this.setHotAreaData(record);
						break;
					case 13: //标签定向栏
						this.setPictureData(record);
						break;
					case 14: //标题
						this.setTitleData(record);
						break;
					case 15: //文本
						this.setTextData(record);
						break;
					case 16: //搜索
						this.setTextData(record);
						break;
					case 17: //限时抢购
						this.setFlashSaleData(record);
						break;
					case 18: //优惠券
						this.setCouponData(record);
						break;
					case 19: //拼团
						this.setPictureData(record);
						break;
					case 20: //积分商城
						this.setPointShopData(record);
						break;
					case 21: //公告
						this.setTextData(record);
						break;
					case 22: //直播
						this.setLiveData(record);
						break;
					case 23: //新品
						this.setNewProductData(record);
						break;
					case 24: //社群专享品
						this.setFlashSaleData(record);
						break;
					case 25: //社群绑定
						this.setBindGroupData(record);
						break;
					case 26: //轮播图
						this.setBannerData(record);
						break;
					case 27: //富文本
						this.setRichTextData(record);
						break;	
				}

				this.isAddData = !this.isAddData;
			
			},
			setProductData(record) {

				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					index: record.index,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],//商品数据
						shopClassifylist: [],
						classifylist: [],
						moduleType: 'shop', //模块类型： 'shop':商品  'classify':分组
						shopType: 'shop',
						styleType: 'tiled',
						column: "3",
						imageType:'1',//单列时图片类型 ,1小图 2，大图
						iconType: 'hide',//是否显示标签
						iconName: '热卖',
						hideSpace: false,//是否显示间距
						btnType:'1',//按钮样式 1，按钮 2，购物车 3，加号 4，无
						pointShow:'show',//卖点是否显示
						priceShow:'show',//价格是否显示
						btnText:'加入购物车',//按钮文字
						countChecked:false,//排序选择销量
						priceChecked:false,//排序选择价格
						newChecked:false,//排序选择新品
					}
				})
			},
			setGuessData(record) {

				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false
					}
				})
			},
			setBindGroupData(record){
				
				var list = this.list.filter(item=>{
					return item.moduleType === 25;
				})
				
				if (list.length){
					this.$message('社群绑定模块仅可添加一个');
					return;
				}
				
				if (this.indexList[record.moduleType]) {
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						hideSpace: false
					}
				})
			},
			setVideoData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						videoUrl: '', //'/ShopDecorate/a8e46c17-e780-4f8c-99bd-be3d42625b55.mp4'
						videoTitle: '',
						videoDesc: '',
						coverImage: '',
						list: [],
						hideSpace: false
					}
				})
			},
			setPictureData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						style: 1, //样式1 样式2
						hideSpace: false
					}
				})
			},
			setHotAreaData(record) {
                console.log('添加热区');
                var num = 1;
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
                    uid: this.guid(),
                    index:num,
					data: {
						visible: false,
						editVisible: false,
						list: [],
						mainUrl: '', ///ShopDecorate/5021f779-b3bd-47e2-a0be-17ba565cc991.jpg
						hideSpace: false
					}
				})
			},
			setTitleData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						url: '',
						title: '',
						style: 1, //居左1，居右2
						hideSpace: false
					}
				})
			},
			setTextData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = 1;
					if(record.moduleType==15){
						num = list.length+1;
					}
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						text: '',
						hideSpace: false,
						selectedData: {},
						showScan: false,
						tbMargin:10,
						lrMargin:10,
						fontSize:14,
						styleType:'left',
						titleBg:'#ffffff',
						fontColor:'#333333',
					}
				})
			},
			setRichTextData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = 1;
					if(record.moduleType==27){
						num = list.length+1;
					}
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
			
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						text: '',
						hideSpace: false,
						titleBg:'#fff',
					}
				})
				console.log("------list----",this.list)
			},
			setFlashSaleData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = 1;
					if(record.moduleType==17){
						num = list.length+1;
					}
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						title: '',
						hideSpace: false,
						activityData: {},
						list: [],
						styleType:'tiled',
					}
				})
			},
			setCouponData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = 1;
					if(record.moduleType==18){
						num = list.length+1;
					}
					// var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						autoFull: 1, //是否自动添加  1是 2否
						couponCount: 1, //自动添加 添加个数
						list: [], //手动添加 添加的优惠券数据
						style: 1, //优惠券样式 1 2 3  不可描述
						colorStyle: 1, //优惠券颜色样式 1.红色 2.黄色 3.黑色
						layoutStyle: '1', //排版样式1大图 2两列 3滑动
						hideSpace: false
					}
				})
			},
			setPointShopData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [], //商品列表
						couponList: [], //优惠券列表
						moduleType: 1, //1.商品 2.优惠券
						hideSpace: false
					}
				})
			},
			setLiveData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [], //直播列表
						hideSpace: false
					}
				})
			},

			setNewProductData(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}

				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						list: [],
						count: 0,
						hideSpace: false
					}
				})
			},
			
			setBannerData(record){
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType];
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					this.indexList[record.moduleType].push(num);
				} else {
					record.tipName = record.name + 1;
					this.indexList[record.moduleType] = [];
					this.indexList[record.moduleType].push(1);
				}
				console.log("-------",record);
				// {
				// 	bannerImgUrl: 'https://cdn.dkycn.cn/images/dyj/no-pic1.jpg',
				// 	pageData: {
				// 		text: ''
				// 	}
				// }
				this.list.push({
					moduleType: record.moduleType,
					name: record.name,
					tipName: record.tipName,
					uid: this.guid(),
					data: {
						visible: false,
						editVisible: false,
						text: '',
						hideSpace: false,
						selectedData: [],
						showScan: false,
						topMargin:10,
						bottomMargin:10,
						lrMargin:10,
					}
				})
			},
			filterRecord(record) {
				if (this.indexList[record.moduleType]) {
					var list = this.indexList[record.moduleType]
					var num = list[list.length - 1] + 1;
					record.tipName = record.name + num;
					record.index = num;
				} else {
					record.tipName = record.name + 1;
					record.index = 1;
				}
				return record;
			},

			moveUp(index) {

				if (index <= 1) return;

				this.list.splice(index - 1, 1, ...this.list.splice(index, 1, this.list[index - 1]))

			},
			moveDown(index) {

				if (index === this.list.length - 1) return;

				this.list.splice(index + 1, 1, ...this.list.splice(index, 1, this.list[index + 1]))

			},
			remove(index) {
				this.list.splice(index, 1)
			},
			deleteMenu(index) {
				this.list.splice(index, 1)
			},
			scrollToElement(record) {
				let elem = document.querySelector('#' + record.uid);
				let box = document.querySelector('.scroll-box');

				if (elem) {
					box.scrollTop = elem.offsetTop - box.offsetTop
				}
			},
			async submit(type, callback) {
				console.log("---submit-----",this.list);
				var list = JSON.parse(JSON.stringify(this.list));
				var array = [];
				list.map(item => {
					if (item.moduleType == 17) {
						item.data.list = item.data.list.map(obj => {
							return {
								ProductId: obj.ProductId
							}
						})
					}
					if (item.moduleType == 24) {
						item.data.list = item.data.list.map(obj => {
							return {
								ProductId: obj.ProductId,
								ActivityFullId: obj.ActivityFullId
							}
						})
					}
					if (item.moduleType == 2) {
						// item.data.list = item.data.list.map(obj => {
						// 	return {
						// 		id: obj.id
						// 	}
						// })
						// item.data.classifylist = item.data.classifylist.map(obj => {
						// 	obj.ProductList = obj.ProductList.map(i => {
						// 		return {
						// 			id: i.id
						// 		}
						// 	})
						// 	return obj;
						// })
						// item.data.shopClassifylist = item.data.shopClassifylist.map(obj => {
						// 	obj.ProductList = obj.ProductList.map(i => {
						// 		return {
						// 			id: i.id
						// 		}
						// 	})
						// 	return obj;
						// })

						if (item.data.moduleType == 'shop' && item.data.shopType == 'shop') {
							item.data.classifylist = [];
							item.data.shopClassifylist = [];
						} else if (item.data.moduleType == 'shop' && item.data.shopType == 'classify') {
							item.data.list = [];
							item.data.classifylist = [];
						} else {
							item.data.list = [];
							item.data.shopClassifylist = [];
						}
					}
					
					if(item.moduleType == 22){
						item.data.list.map(obj=>{
							obj.CoverImgUrl = obj.AnchorImgUrl;
							return obj;
						})
					}
					
					array.push(item)
				})

				const params = {
					id: this.pageId,
					decorateData: JSON.stringify(array),
					pageName: this.list.filter(item => {
						return item.moduleType == 1;
					})[0].data.title,
				}
                var result;
                if (params.id) {
                    result = await getPageEdit(params);
                }else{
                    result = await getPageAdd(params);
                }

				if (result.success) {
					if (!callback) {
						this.$message({
							showClose: true,
							message: '保存成功！',
							type: 'success'
						});
					}
                    callback && callback();
                    if (!params.id) {
                        setTimeout(()=>{
                            this.$store.state.tagsView.visitedViews = this.$store.state.tagsView.visitedViews.filter(
                                (item) => {
                                    return item.path != this.$route.path;
                                }
                            );
                            this.$router.replace({
                                path: '/mallPage/mallPagemange'
                            })
                        }, 1000)
                    }
					

				} else {
					this.$message.error(result.alertMsg);
				}

			},
			cancel() {
				// this.$confirm('若您未保存编辑的数据，离开页面后，系统将不会为您保存数据，是否确认继续离开？')
				// 	.then(_ => {
				// 		this.$router.replace({
				// 			path: '/mallPage/mallPagemange'
				// 		})
				// 	})
				// 	.catch(_ => {});
				this.dialogVisible = true;

			},
			handleCanMove(record, index, uid) {
				this.$refs['browseBox'].hotMove(record, index, uid)
				console.log(record, index, uid)
			},
			sureCancel() {
				this.dialogVisible = false;
				this.$router.replace({
					path: '/mallPage/mallPagemange'
				})
			},
			preview() {

				this.submit(false, async () => {
					this.weiCodeShow = true;
					try {
						this.codeding = true
						let data = {
							Page: `pages/cusPage/cusPage`,
							Scene: `id=${this.pageId}&view=1`,
							IsLimited: false,
							ProgramType: 0,
						}
						let result = await apiList.mallGetQrCode(data)
						this.codePicture = JSON.parse(result.Result)[0]
					} catch (e) {
						console.log('e', e)
					} finally {
						this.codeding = false
					}
				})


			},
			weiCodeCancel() {
				this.weiCodeShow = false;
			},
			guid() {
				var a = function() {
					return (65536 * (1 + Math.random()) | 0).toString(16).substring(1)
				};
				return 'a' + a() + a() + "-" + a() + "-" + a() + "-" + a() + "-" + a() + a() + a()
			}
		},
		mounted() {
			this.initData();
		},
		watch: {}
	};
</script>

<style lang="less" scoped>
	.page-manage {
		display: flex;
		justify-content: space-between;
		background: #e5e5e5;
		overflow: auto;
		position: absolute;
		left: 0px;
		top: 0;
		width: 100%;
		height: 100%;

		.edit-box {
			display: flex;
			width: 480px;
			background: #fff;
			margin-left: 50px;
		}

		.item-box {
			display: flex;
			height: 100%;
		}

		.fixed-menu {
			position: fixed;
			right: 20px;
			top: 125px;
			width: 30px;
			height: 30px;
			line-height: 37px;
			background: #fff;
			text-align: center;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);
			cursor: pointer;
		}

		.dragMenu {
			width: 240px;
			margin-left: 584px;
			position: fixed;
			top: 160px;
			right: 60px;
			bottom: 60px;
			z-index: 1000;
			box-shadow: 0 0 9px 1px rgba(0, 0, 0, .1);

			.box {
				display: flex;
				flex-direction: column;
				height: 100%;
				background: #fff;
				padding: 15px 20px;

				.title {
					font-size: 16px;
					margin-bottom: 15px;
					flex: 0 0 auto;
				}

				.item {
					position: relative;
					height: 36px;
					line-height: 36px;
					padding-left: 20px;
					font-size: 14px;
					color: #666;
					background: #f0f0f0;
					margin-bottom: 5px;
					cursor: pointer;

					.menu-delete {
						position: absolute;
						right: 10px;
						top: 2px;
						cursor: pointer;
					}
				}

				.list {
					flex: 1 1 auto;
					overflow: auto;
					padding-right: 10px;
				}
			}
		}

		.btn-box {
			position: absolute;
			right: 10px;
			bottom: 15px;
		}
	}

	* {
		user-select: none;
	}

	// ::-webkit-scrollbar {
	// width: 5px;
	// }

	// ::-webkit-scrollbar-track {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:hover {
	// background-color: none;
	// }

	// ::-webkit-scrollbar-thumb:active {
	// background-color: none;
	// }
</style>
